<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="展示日期：">
        <el-date-picker v-model="date" size="small" @change="getList" type="date" value-format="timestamp" :clearable="false"></el-date-picker>
      </el-form-item>
      <el-form-item style="margin-right: 50px">
        <el-button plain size="small" @click="targetSet">预约设置</el-button>
      </el-form-item>
      <el-form-item>
        <div class="status">
          <div>
            <span></span>
            未约满
          </div>
          <div>
            <span></span>
            已约满
          </div>
          <div>
            <span></span>
            空闲
          </div>
          <div>
            <span></span>
            休息
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="lookBoard" v-if="tiemInterval">
      <div class="left" v-if="is_scroll" @click="leftScroll">
        <img src="../../assets/left.png" alt="" />
      </div>
      <div class="right" v-if="is_scroll" @click="rightScroll">
        <img src="../../assets/right.png" alt="" />
      </div>
      <div class="time-interval">
        <div class="no"></div>
        <div class="interval-item" v-for="(item, index) in tiemInterval" :key="index">{{ item }}</div>
      </div>
      <div class="employee-list" ref="employee_list">
        <div class="employee-box" :style="move">
          <div class="employee-item" v-for="(item, index) in employee" :key="index">
            <div class="employee-name" :style="{ background: item.scheduling ? '' : '#E6EAF2', color: item.scheduling ? '' : 'rgba(44, 62, 80, 0.4)' }">{{ item.service_name }}</div>
            <div class="time_d">
              <div
                class="time-item"
                :style="{ background: time.num == 0 ? '#fff' : time.num > 0 && time.num < time.pre_service_num ? '#ff9b67' : '#5dadff' }"
                v-for="(time, indexs) in item.scheduling ? item.scheduling[0].time_d : []"
                :key="indexs"
              >
                <el-popover v-if="time.num" placement="left-bottom" width="389" trigger="click" :append-to-body="false">
                  <div class="board_info" v-for="(user, i) in time.appoint" :key="i">
                    <div class="user_info" :style="{ 'border-bottom': time.appoint.length - 1 != i ? '1px solid #F5F5F5' : '' }">
                      <img :src="user.avatar" alt="" />
                      <div class="user_right">
                        <div class="nickname">
                          <span>{{ user.nick_name || '--' }}</span>
                          <span>x {{ user.num }} 人</span>
                        </div>
                        <div class="name">
                          <span>{{ user.name || '--' }}</span>
                          <div>{{ user.status == 0 ? '已取消' : user.status == 10 ? '待到店' : user.status == 20 ? '已到店' : user.status == 30 ? '已开单' : '已完成' }}</div>
                        </div>
                        <div>洗牙服务x{{ user.num }}</div>
                      </div>
                    </div>
                  </div>
                  <div slot="reference" class="board_item">
                    <div>{{ time.num >= time.pre_service_num ? '已约满' : '未约满' }}</div>
                    <div>{{ time.num }}人</div>
                  </div>
                </el-popover>
              </div>
              <template v-if="!item.scheduling">
                <div class="time-item" style="background: #e6eaf2" v-for="(item, index) in tiemInterval" :key="index"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <No v-else></No>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      employee: [],
      tiemInterval: [],
      move: {},
      scrollWidth: 0,
      width: 0,
      is_scroll: !1,
      date: Date.now(),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // getSetInfo() {
    //   this.$axios.post(this.$api.beauty.setinfo).then(res => {
    //     if (res.code == 0) {
    //       if (res.result) {
    //         let info = res.result;
    //         let appointment_time_frame = (info.appointment_time_frame = JSON.parse(info.appointment_time_frame)); //设置的时间段
    //         let appointment_interval_time = info.appointment_interval_time; //时间间隔 15,30,1,2
    //         let scaleTime = []; //刻度时间段
    //         //处理设置的时间段
    //         for (let i in appointment_time_frame) {
    //           for (let y in appointment_time_frame[i]) {
    //             let { hour, minutes } = getDate.getDate(Number(appointment_time_frame[i][y]) * 1000);
    //             appointment_time_frame[i][y] = { hour: Number(hour), minutes: Number(minutes) };
    //           }
    //         }
    //         //处理刻度时间段
    //         for (let i in appointment_time_frame) {
    //           let start_minute = appointment_time_frame[i][0].minutes; //开始的分钟
    //           let end_minute = appointment_time_frame[i][1].minutes; //结束的分钟
    //           let is_integer = start_minute ? !0 : !1; //设置的最开始时间是不是整数
    //           for (let y = appointment_time_frame[i][0].hour; y < appointment_time_frame[i][1].hour; y++) {
    //             switch (appointment_interval_time) {
    //               case 15:
    //                 var timeNum = 4;
    //                 if (is_integer) {
    //                   timeNum = 2;
    //                   is_integer = !1;
    //                 }
    //                 for (var k = 0; k < timeNum; k++) {
    //                   scaleTime.push(`${y}:${start_minute == 60 ? '00' : start_minute < 10 ? '00' : start_minute}`);
    //                   start_minute += 15;
    //                 }
    //                 //一个时间段最后一小时
    //                 if (y + 1 == appointment_time_frame[i][1].hour) {
    //                   scaleTime.push(`${y + 1}:${start_minute == 60 ? '00' : start_minute < 10 ? '00' : start_minute}`);
    //                   //一个时间段结束的时间是不是30分钟
    //                   if (end_minute) {
    //                     start_minute = 0;
    //                     for (let k = 0; k < 2; k++) {
    //                       start_minute += 15;
    //                       scaleTime.push(`${y + 1}:${start_minute == 60 ? '00' : start_minute < 10 ? '00' : start_minute}`);
    //                     }
    //                   }
    //                 }
    //                 if (start_minute == 60) start_minute = 0;
    //                 break;
    //               case 30:
    //                 var timeNum = 2;
    //                 if (is_integer) {
    //                   timeNum = 1;
    //                   is_integer = !1;
    //                 }
    //                 for (var k = 0; k < timeNum; k++) {
    //                   scaleTime.push(`${y}:${start_minute == 60 ? '00' : start_minute < 10 ? '00' : start_minute}`);
    //                   start_minute += 30;
    //                 }
    //                 //一个时间段最后一小时
    //                 if (y + 1 == appointment_time_frame[i][1].hour) {
    //                   scaleTime.push(`${y + 1}:${start_minute == 60 ? '00' : start_minute < 10 ? '00' : start_minute}`);
    //                   //一个时间段结束的时间是不是30分钟
    //                   if (end_minute) {
    //                     start_minute = 0;
    //                     start_minute += 30;
    //                     scaleTime.push(`${y + 1}:${start_minute == 60 ? '00' : start_minute < 10 ? '00' : start_minute}`);
    //                   }
    //                 }
    //                 if (start_minute == 60) start_minute = 0;
    //                 break;
    //               case 1:
    //                 if (is_integer) {
    //                   scaleTime.push(`${y}:30`);
    //                 } else {
    //                   scaleTime.push(`${y}:00`);
    //                 }
    //                 if (y + 1 == appointment_time_frame[i][1].hour) {
    //                   let time_str = end_minute ? `${y + 1}:30` : `${y + 1}:00`;
    //                   let end_time = `${appointment_time_frame[i][1].hour}:${appointment_time_frame[i][1].minutes < 10 ? '00' : appointment_time_frame[i][1].minutes}`;
    //                   scaleTime.push(time_str);
    //                   //判断最后一次刻度够不够一次阶段的结束时间
    //                   if (time_str != end_time) scaleTime.push(end_time);
    //                 }
    //                 break;
    //               case 2:
    //                 if (is_integer) {
    //                   scaleTime.push(`${y}:30`);
    //                 } else {
    //                   scaleTime.push(`${y}:00`);
    //                 }
    //                 y = y + 1;
    //                 if (y == appointment_time_frame[i][1].hour) {
    //                   let time_str = end_minute ? `${y}:30` : `${y}:00`;
    //                   let end_time = `${appointment_time_frame[i][1].hour}:${appointment_time_frame[i][1].minutes < 10 ? '00' : appointment_time_frame[i][1].minutes}`;
    //                   scaleTime.push(time_str);
    //                   //判断最后一次刻度够不够一次阶段的结束时间
    //                   if (time_str != end_time) scaleTime.push(end_time);
    //                 }
    //                 break;
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    leftScroll() {
      if (this.scrollWidth > 0) {
        this.scrollWidth = this.scrollWidth - 126;
        if (this.scrollWidth < 0) this.scrollWidth = 0;
        this.move = {
          transform: `translateX(-${this.scrollWidth}px)`,
          transition: 'all 0.3s linear',
        };
      }
    },
    rightScroll() {
      let { scrollWidth, clientWidth } = this.$refs.employee_list;
      if (scrollWidth - clientWidth < 126) {
        this.scrollWidth = this.width - clientWidth;
      } else {
        this.scrollWidth = this.scrollWidth + 126;
      }
      if (this.scrollWidth > 0) {
        this.move = {
          transform: `translateX(-${this.scrollWidth}px)`,
          transition: 'all 0.3s linear',
        };
      }
    },
    getList() {
      this.$axios
        .post(this.$api.beauty.lookBoard, {
          date: getDateformat(parseInt(this.date / 1000)).slice(0, 10),
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.tiemInterval = res.result.scheduling;
            let currentHour = getDateformat().slice(11, 16).split(':').map(Number);
            console.log(currentHour)
            // for (let i in time_d) {
            //   let hourNum = 0;
            //   let hour = time_d[i].times.split(':').map(Number);
            //   hourNum = hour[0] * 60 * 60;
            //   hourNum = hourNum + hour[1] * 60;
            //   time_d[i].hourNum = hourNum;
            // }
            // for (let i in this.tiemInterval) {
            // }
            this.employee = list;
            this.$nextTick(() => {
              this.width = this.$refs.employee_list.scrollWidth;
              this.is_scroll = this.$refs.employee_list.scrollWidth - this.$refs.employee_list.clientWidth;
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    targetSet() {
      this.$router.push({ path: '/reserve/reserveSet' });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .status {
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      margin-right: 30px;
      span {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
      &:nth-child(1) span {
        background: #ff9b67;
      }
      &:nth-child(2) span {
        background: #5dadff;
      }
      &:nth-child(3) span {
        background: #fff;
        border: 1px solid #c0c4cc;
      }
      &:nth-child(4) span {
        background: #e6eaf2;
      }
    }
  }
  .lookBoard {
    position: relative;
    border-top: 1px solid #e6e6e6;
    display: flex;
    .left {
      position: absolute;
      z-index: 10;
      left: 96px;
      top: 24px;
      cursor: pointer;
      img {
        width: 10px;
        height: 16px;
      }
    }
    .right {
      position: absolute;
      z-index: 10;
      right: 0;
      top: 24px;
      cursor: pointer;
      img {
        width: 10px;
        height: 16px;
      }
    }
    .time-interval {
      background: #fff;
      .no {
        width: 96px;
        height: 65px;
        border-bottom: 1px solid #e6e6e6;
      }
      .interval-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 100px;
        color: #2c3e50;
        border-bottom: 1px solid #e6e6e6;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
      }
    }
    .employee-list {
      flex: 1;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
      .employee-box {
        display: flex;
      }
      .employee-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        .employee-name {
          height: 65px;
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          min-width: 126px;
          align-items: center;
          justify-content: center;
          color: #2c3e50;
        }
        .time_d {
          display: flex;
          flex-direction: column;
          min-width: 126px;
          .time-item {
            display: flex;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
            color: #fff;
            text-align: center;
            /deep/ .el-popover {
              padding: 0;
            }
            & > span {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              /deep/ span {
                width: 100%;
                height: 100%;
                & > div {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                }
              }
            }
            .board_info {
              padding: 0 20px;
            }
            .user_info {
              display: flex;
              color: #606266;
              height: 110px;
              padding: 20px 0;
              .user_right {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .nickname {
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: space-between;
                }
                .name {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 10px;
                  div {
                    width: 60px;
                    height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0px 0px 0px 0px;
                    border: 1px solid #409eff;
                  }
                }
              }
              img {
                width: 40px;
                height: 40px;
                margin-right: 28px;
                border-radius: 50%;
              }
            }
            .board_item {
              width: 100%;
              div:nth-child(1) {
                margin-bottom: 8px;
                font-size: 16px;
              }
              div:nth-child(2) {
                font-weight: bold;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
